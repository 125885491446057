// Libraries
import { initializeApp  } from 'firebase/app';
import { getFirestore, onSnapshot, collection, query, where, orderBy, limit } from "firebase/firestore";
import {firebaseConfig} from './FirebaseConfig';

/**
 * Creates a Firebase collection listener
 * 
 * @param {string} collectionId
 * @param {Array} queries
 * @param {function} onLoad
 * @param {function} onChange
 * @param {function} onError
 * @param {Array} sortBy - <OPTIONAL> Sort by a field, requires an index
 * @param {Number} limitBy <OPTIONAL> Limit the query to x number of documents
 * 
 * ```
 * useEffect(()=>{
 * 
 * const collectionId = 'users';
 * const queries = [
 *  ['emailaddress', '==', 'mark.bennett@lendlease.com'],
 * ];
 * const sortBy = ['created', 'desc'];
 * const limitBy = 5;
 * 
 *   if (productId === undefined) return;
 * 
 *   function onLoad(document){
 *     setProductObject(document);
 *   }
 * 
 *   function onChange(document, previousValue){
 *     if(previousValue === undefined) return;
 *     if(previousValue?.pricingamount !== document?.pricingamount) setShowModal(true);   
 *   }
 * 
 *   function onError(error){
 *     setRequestType('error-fatal');
 *   }
 * 
 *   const unsubscribe = QueryListener('workflows', queries, onLoad, onChange, onError, sortBy, limitBy);
 * 
 *   return () =>{
 *     unsubscribe();
 *   };
 * 
 * }, [productId]);
 * 
 * ```
 * 
 * Documentation
 * - https://firebase.google.com/docs/firestore/query-data/listen
 * - https://firebase.google.com/docs/reference/android/com/google/firebase/firestore/DocumentSnapshot
 */

export default function QueryListener(collectionId, queries, onLoad, onChange, onError, sortBy, limitBy){

  try {

    //Firestore database reference
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    
    //Set collection
    const collectionRef = collection(db, collectionId);

    //------------------------------------------------------
    //  Prepare query arguments 
    //------------------------------------------------------

    //Temp array used to store query arguments
    var args = []

    //Extract list of queries from array
    if(queries.length > 0){
      queries.forEach((query) => {
        const queryArg = where(query[0], query[1], query[2])
        args.push(queryArg)
      })
    }

    //Check if sortBy exists > add to query arguments
    if(sortBy?.length > 0) {
      const orderByArg = orderBy(sortBy[0], sortBy[1])
      args.push(orderByArg)
    }

    //Check if limitBy exists > add to query arguments
    if(limitBy) {
      const limitArg = limit(limitBy)
      args.push(limitArg)
    }

    //Create query
    const q = query(collectionRef, ...args);


    // Invoke listener > call 'onLoad' function
    const unsubscribe = onSnapshot(q, (snapshot) => {

      const results = [];

      snapshot.forEach((doc) => {

        results.push(doc.data());
      
      });

      onLoad(results);

    });


    // Invoke listener > call 'onChange' function
    onSnapshot(q, (snapshot) => {

      const results = [];

      snapshot.forEach((doc) => {

        results.push(doc.data());
      
      });

      onChange(results);

    })

    // Return the callback function > Allows the caller to stop listening to changes
    return unsubscribe;

  } catch (error) {

    onError(`QueryListener has failed to complete, error: ${error}`);

  }

}
