//================================================================
//  Application:AES Portal
//================================================================

//  Product Owner: Gary Searle
//  Created 15/02/24

//================================================================

//Libraries
import React from 'react';
import { Routes, Route } from 'react-router-dom';

//Contexts

//Pages
import Search from './Pages/Search/Search.js';
import Browse from './Pages/Browse/Browse.js';
import NotFound from './Pages/NotFound/NotFound.js';

//Components
import Navbar from './Components/Navbar/Navbar';
import DisclaimerModal from './Components/DisclaimerModal/DisclaimerModal.js';
import GlobalToast from './Components/GlobalToast/GlobalToast.js';

//Styles
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css';


export default function App() {

  //------------------------------------------------------
  //  Return HTML
  //------------------------------------------------------

  return (
    <div className='App-Container'>

      {/* Nav */}
      <div className='App-Container-Nav'>
        <Navbar></Navbar>
      </div>

      {/* Body > Contains all pages in the app  */}
      <div className='App-Container-Body'>
       
        {/* Disclaimer */}
        <DisclaimerModal></DisclaimerModal>

        {/* Toast */}
        <GlobalToast></GlobalToast>

        <Routes>
          <Route path='/' element={<Search />} />
          <Route path='/browse/:id' element={<Browse />} />
          <Route path='*' element={<NotFound />}></Route>
        </Routes>

      </div>

    </div>
  )

  //------------------------------------------------------

}
