//================================================================
//  Component: Navbar
//================================================================

//  Purpose: The Navbar for Events App

//  Example:
//    <Navbar></Navbar>


//================================================================


//Libraries
import React, { useState, useEffect, useContext } from 'react';

// Contexts
import { GetUser } from '../../Library/GlobalContexts.js';

//Components
import Logout from '../../Library/Logout';

//Images
import LLLogo from '../Images/Icon_LLLogo_Green.png';

//CSS
import './Navbar.css';


export default function Navbar() {

  //------------------------------------------------------
  //  useContexts and React Router
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);

  //------------------------------------------------------
  //  Define useStates
  //------------------------------------------------------

    // Used to change between > 'pending', 'success'
    const [pageStatus, setPageStatus] = useState('pending');

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // OnLoad
    useEffect(() => {

      if (getUser?.roles === undefined) return setPageStatus('success');

    },[pageStatus, getUser]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <div>

        {/* ---------------------------------------------------- */}
        {/*  Nav Header                                          */}
        {/* ---------------------------------------------------- */}

        <div className='flex flex-row justify-between items-center bg-[#141C25] text-[white] px-[10px] py-0 h-[70px] [@media(max-width:450px)]:pl-0 [@media(max-width:450px)]:pr-5'>
    
          {/* Logo */}
          <div className='flex items-center p-[25px]'>
       
            <img className='mr-[20px]' src={LLLogo} alt='Lendlease Logo' width='55px' height='39px'></img>

            <h5 className='font-medium m-0'> Data Archive Service (DAS)</h5>

          </div>

          <Logout></Logout>

        </div>
        
        {/* ---------------------------------------------------- */}
        {/*  Nav Menu                                            */}
        {/* ---------------------------------------------------- */}

        {/* <div className='flex flex-row gap-2 border-b-[#C8C8C9] border-solid border-b px-[3%] m-0 h-[50px] items-center shadow-sm'> */}

          {/* Search */}
          {/* <NavItem style={changeStyleHandler('/')} link={'/'} title={'Search'} roles={['isLLUser']}></NavItem> */}

        {/* </div> */}

      </div>
    )

}
