//================================================================
//  Component: Disclaimer Modal component
//================================================================

//  Purpose: Users must accept our disclaimer & have asked less than 500 questions
//           If so, they can proceed to the App

//  Example:
//    <DisclaimerModal></DisclaimerModal>    

//================================================================


//Libraries
import React, { useState, useContext, useEffect } from 'react';

//Contexts
import { GetUser, SetAppStatus, SetAppErrors } from '../../Library/GlobalContexts';

//Components

//Functions
import QueryListener from '../../Library/QueryListener';

//Images
import importantIcon from '../../Components/Images/Icon_Important_Blue.svg';
import lightBlub from '../../Components/Images/Icon_LightBlub_Blue.svg';
import warningIcon from '../../Components/Images/Icon_Warning_Yellow.svg'

//CSS
import './DisclaimerModal.css';


export default function DisclaimerModal() {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

  const getUser = useContext(GetUser);
  const setAppStatus = useContext(SetAppStatus);
  const setAppErrors = useContext(SetAppErrors);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  // If the modal is open/closed
  const [disclaimerModalLoading, setDisclaimerModalLoading] = useState(true);
  const [disclaimerModalOpen, setDisclaimerModalOpen] = useState(true);

  // List of the users datasets
  const [dataSets, setDataSets] = useState([]);

  //------------------------------------------------------
  //  useEffect
  //------------------------------------------------------

  useEffect(() => {

    if (getUser === undefined) return;
    if (getUser?.emailaddress === undefined) return;

    function onLoadChange(documents) {

      setDataSets(documents);
      setDisclaimerModalLoading(false);

    }

    function onError(error) {

      console.log(error);
      setAppStatus('failed');
      setAppErrors(`Unable to get Datasets, refresh the window and try again. If the error persists, contact your administrator.`);

    }

    QueryListener('datasets', [
      ['users', 'array-contains', getUser?.emailaddress],
      ['status', '==', 'active']
    ], onLoadChange, onLoadChange, onError);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUser]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  // Dont show model until data is loaded
  if (disclaimerModalLoading === true) return null;

  // User has NO projects, prevent access!
  if (dataSets.length === 0) {

    return (
      <div className='Modal-Background'>

        <dialog style={{ width: '650px', top: '25%' }} className='DisclaimerModal-Container'>

          <h4 className='p-[10px] border-b border-solid border-b-[2px_solid_#d1d1d1] m-[10px] pb-[10px] mb-[20px]'>
            Oops, you do not have any data sources!
          </h4>

          <div className='flex flex-col p-[30px]'>

            <div className='flex flex-row items-center bg-[#141C25]'>
              <img className='mr-[10px] w-[25px]' src={lightBlub} alt='question-icon'></img>
              Contact your data owner(s) to be granted access to at least one data source.
            </div>

          </div>

        </dialog>

      </div>
    )

  }

  //------------------------------------------------------
  //  Disclaimer Modal
  //------------------------------------------------------

  if (disclaimerModalOpen !== true && disclaimerModalLoading === false) return null;

  return (
    <div className='Modal-Background'>

      <dialog className='DisclaimerModal-Container'>

        <h4 className='p-[5px] border-b border-solid border-b-[2px_solid_#d1d1d1] m-[10px] pb-[10px] mb-[20px]'>
          Welcome to Data Archive Service (DAS)
        </h4>

        <div className='flex flex-col text-[white] p-[10px]'>

          {/* Disclaimer when deploying PoC instance */}
          {
            process.env.REACT_APP_POC_DISCLAIMER === 'true' &&
            <div className='flex flex-row mb-[20px]'>
              <img className='mr-[10px] w-[25px]' src={warningIcon} alt='warning-icon'></img>
              PoC environment is not for production purposes!
            </div>
          }

          <div className='flex flex-row'>
            <img className='mr-[10px] w-[25px]' src={importantIcon} alt='important-icon'></img>
            Couple of guidelines to follow:
          </div>

          <div className='pl-[20px] pt-1'>
            <ul className='list-disc mt-[10px]'>
              <li>
                Restricted to authorized personnel only.
              </li>
              <li>
                The Data contained within is for historical and archival purposes only.
              </li>
              <li>
                Users are cautioned that the Data may be outdated, incomplete, or inaccurate.
              </li>
              <li>
                Any unauthorized access, use, or disclosure of the Data is strictly prohibited.
              </li>
            </ul>

          </div>

        </div>

        <div className='flex flex-row-reverse items-end pb-[10px] pr-[10px]'>
          <button className='DisclaimerModal-Primary-Button' onClick={() => setDisclaimerModalOpen(false)}>Accept the guidelines</button>
        </div>

      </dialog>

    </div>
  )

  //------------------------------------------------------
}
