// Libraries
import { getApp } from 'firebase/app';
import { getStorage, ref, getBlob } from 'firebase/storage';

/**
 * Generic function to download a Firebase blob from a Firebase GCS bucket.
 * To use this functionality, you have to whitelist your app's origin in your Cloud Storage bucket. See also https://cloud.google.com/storage/docs/configuring-cors
 * 
 * IMPORTANT - Terraform 'google_storage_bucket' block MUST include: 
 *    cors {
 *      origin          = ["*"]
 *      method          = ["GET"]
 *      response_header = ["*"]
 *      max_age_seconds = 3600
 *    }
 * 
 * @param {string} path
 * @param {string} bucketname
 * ```
 * const path = 'images/rads_legend.png';
 * const bucketname = 'bucket-123';
 * 
 * DownloadBlob(path, bucketname).then((blob) =>{
 * 
 *    // Create URL from blob
 *    const url = window.URL.createObjectURL(blob);
 *
 *    // Create new Element -> Add the file name & URl
 *    const a = document.createElement('a');
 *    a.download = 'INSERT_FILE_NAME.csv';
 *    a.href = url;
 *
 *    // Trigger download
 *    a.click();
 * 
 * }).catch((error) =>{
 * 
 *   console.log('error', error);
 * 
 * });
 * 
 * ```
 * 
 * Documentation
 * - https://firebase.google.com/docs/reference/js/storage.md#getblob_1c7a935
 * - https://cloud.google.com/storage/docs/configuring-cors
 * - https://registry.terraform.io/providers/hashicorp/google/latest/docs/resources/storage_bucket#cors
 */

export default async function DownloadBlob(path, bucketname){

  try {

    let storage;

    if (bucketname === undefined) {

      //Create a root reference
      storage = getStorage();
    
    } else {

      const firebaseApp = getApp();
      storage = getStorage(firebaseApp, bucketname);

    }

    // Create a reference to 'file' we want to download
    // 'images/figma.png'
    const storageRef = ref(storage, path);

    return getBlob(storageRef).then((blob) =>{

      return blob;

    });

  } catch (error) {

    throw new Error(`Function DownloadBlob failed to complete, error ${error}`);

  }

}
