//================================================================
//  Component: Global Toast
//================================================================

//  Purpose: Generic Toast component with the useContext 'GetToast' & 'SetToast'

// IMPORTANT: DO NOT change useContexts WITHOUT consulting the team.

//  Example:
//    <GlobalToast></GlobalToast>    

//================================================================


//Libraries
import React, { useContext } from 'react';

//Contexts
import { GetToast, SetToast } from '../../Library/GlobalContexts';

//Components

//Functions

//Images
import infoIcon from '../Images/Icon_Info_Black.svg';
import pendingIcon from '../Images/Icon_Loading_Green.svg';
import successIcon from '../Images/Icon_Approved_Green.svg';
import warningIcon from '../Images/Icon_Warning_Yellow.svg';
import errorIcon from '../Images/Icon_Error_Red.svg';
import closeIcon from '../Images/Icon_Clear_Grey.svg';


export default function GlobalToast() {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------
  
    const getToast = useContext(GetToast);
    const setToast = useContext(SetToast);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  // ======== Hide Toast ======== //
  if (getToast?.type === '') return null;
  if (getToast?.type === undefined) return null;
  if (getToast?.message === undefined) return null;
  if (getToast?.message === '') return null;

  // ======== info ======== //
  if (getToast.type === 'info'){

    return (
      <div className='flex flex-row absolute bottom-10 right-10 bg-[#E3F1FB] px-3 py-2 rounded-md gap-3 max-w-[50%]'>
        <img src={infoIcon} alt='Info-Icon'></img>
        <span className='max-w-[calc(100vw-80%)] overflow-hidden text-nowrap text-ellipsis cursor-default'>{getToast?.message}</span>
        <img className='cursor-pointer' onClick={() => setToast({'type': '', 'message': ''})} src={closeIcon} alt='Close-Icon'></img>
      </div>
    )
  
  }

  // ======== pending ======== //
  if (getToast.type === 'pending'){

    return (
      <div className='flex flex-row absolute bottom-10 right-10 bg-[#E3F1FB] px-3 py-2 rounded-md gap-3 max-w-[50%]'>
        <img className='animate-spin' src={pendingIcon} alt='Pending-Icon'></img>
        <span className='max-w-[calc(100vw-80%)] overflow-hidden text-nowrap text-ellipsis cursor-default'>{getToast?.message}</span>
        <img className='cursor-pointer' onClick={() => setToast({'type': '', 'message': ''})} src={closeIcon} alt='Close-Icon'></img>
      </div>
    )
  
  }

  // ======== success ======== //
  if (getToast.type === 'success'){

    return (
      <div className='flex flex-row absolute bottom-10 right-10 bg-[#E3F1FB] px-3 py-2 rounded-md gap-3 max-w-[50%]'>
        <img src={successIcon} alt='Success-Icon'></img>
        <span className='max-w-[calc(100vw-80%)] overflow-hidden text-nowrap text-ellipsis cursor-default'>{getToast?.message}</span>
        <img className='cursor-pointer' onClick={() => setToast({'type': '', 'message': ''})} src={closeIcon} alt='Close-Icon'></img>
      </div>
    )
  
  }

  // ======== warning ======== //
  if (getToast.type === 'warning'){

    return (
      <div className='flex flex-row absolute bottom-10 right-10 bg-[#E3F1FB] px-3 py-2 rounded-md gap-3 max-w-[50%]'>
        <img src={warningIcon} alt='Warning-Icon'></img>
        <span className='max-w-[calc(100vw-80%)] overflow-hidden text-nowrap text-ellipsis cursor-default'>{getToast?.message}</span>
        <img className='cursor-pointer' onClick={() => setToast({'type': '', 'message': ''})} src={closeIcon} alt='Close-Icon'></img>
      </div>
    )
  
  }

  // ======== error ======== //
  if (getToast.type === 'error'){

    return (
      <div className='flex flex-row absolute bottom-10 right-10 bg-[#E3F1FB] px-3 py-2 rounded-md gap-3 max-w-[50%]'>
        <img src={errorIcon} alt='Error-Icon'></img>
        <span className='max-w-[calc(100vw-80%)] overflow-hidden text-nowrap text-ellipsis cursor-default'>{getToast?.message}</span>
        <img className='cursor-pointer' onClick={() => setToast({'type': '', 'message': ''})} src={closeIcon} alt='Close-Icon'></img>
      </div>
    )
  
  }

}
