//================================================================
//  Page: search
//================================================================

//  Purpose: Shows all the search results

//  Example:
//    <search></search>    

//================================================================

//Libraries
import React, { useContext, useState, useReducer, useEffect } from 'react';

//Contexts
import { GetUser } from '../../Library/GlobalContexts';

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import DatasetTable from './Components/DatasetTable';
import SearchResults from './Components/SearchResults';
import SearchTools from './Components/SearchTools';

//Functions
import QueryDocument from '../../Library/QueryDocument';
import QueryListener from '../../Library/QueryListener'

//Images
import IconSearch from '../../Components/Images/Icon_Search_Grey.svg';
import IconClear from '../../Components/Images/Icon_Clear_Grey.svg';
import Arrow from '../../Components/Images/Icon_Breadcrumb_Black.svg';


export default function Search() {

    //------------------------------------------------------
    //  useContext & React Router
    //------------------------------------------------------

    const getUser = useContext(GetUser);

    //------------------------------------------------------
    //  useStates
    //------------------------------------------------------

    // Used to save page status > 'pending', 'onload', 'error-invalid', 'error-fatal'
    const [pageStatus, setPageStatus] = useState('onload');

    // Datasets
    const [dataSets, setDataSets] = useState([]);
    const [dataSetsFiltered, setDataSetsFiltered] = useState([]);

    //------------------------------------------------------
    //  useReducer
    //------------------------------------------------------

    // Used to store the uploaded file object
    const [search, setSearch] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            // ===========================
            // File search & results
            // ===========================

            'searchInput': '',
            'resultsOpen': false,
            'results': [],

            // Tools container
            'toolsOpen': false,

            // Open or Close dropdowns
            'trayOpen': '', // source or time

            // Dataset
            'datasetSelected': 'Any dataset',

            // Time
            'timeSelected': 'Any time',
            'timeRanges': [
                'Any time',
                'Past month',
                'Past 3 months',
                'Past 6 months',
                'Past year',
                'Custom range...',
            ],

            // Custom time range   
            'openTimeCustomRange': false,
            'timeStart': '',
            'timeEnd': '',

        }
    );

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    function handleSearch(keyCode, startTime, endTime, datasetSelected) {

        if (keyCode !== 13) return;
        if (dataSets.length === 0) return; // Shouldnt be possible to get here!

        const query = [
            ['searchvalues', 'array-contains-any', [search.searchInput.toLowerCase()]],
        ];

        // =======================================
        //  'startTime' filter
        // =======================================

        if (startTime !== 'Any time' && endTime === undefined) {

            let months = 0;
            if (startTime === 'Past month') months = 1;
            if (startTime === 'Past 3 months') months = 3;
            if (startTime === 'Past 6 months') months = 6;
            if (startTime === 'Past year') months = 12;

            const today = new Date();
            const startDate = new Date(today.setMonth(today.getMonth() - months));

            query.push(
                ['created', '>', startDate],
            );

        }

        // =======================================
        //  'startTime' & 'endTime' filter
        // =======================================

        if (startTime !== 'Any time' && endTime !== undefined) {

            const start = new Date(startTime);
            start.setHours(start.getHours() - 24);
            const end = new Date(endTime);
            end.setHours(start.getHours() + 24);

            query.push(
                ['created', '>', new Date(start)],
                ['created', '<', new Date(end)],
            );

        }

        // =======================================
        //  'dataset' filters
        // =======================================

        const searchPromises = [];

        if (datasetSelected === 'Any dataset') {

            // All projects
            dataSets.forEach((object) => {

                searchPromises.push(
                    QueryDocument('files', [
                        ...query,
                        ['datasetid', '==', object.datasetid],
                    ]
                    ),
                );

            });

        } else {

            // Selected projects
            searchPromises.push(
                QueryDocument('files', [
                    ...query,
                    [`datasetid`, '==', datasetSelected],
                ]
                ),
            );

        }

        // =======================================
        // Settle search promises
        // =======================================

        Promise.all(searchPromises).then((results) => {

            const documents = [];

            results.forEach((arrays) => {

                documents.push(...arrays);

            });

            setSearch({
                'results': documents,
                'resultsOpen': true
            });

        }).catch((error) => {

            console.log(error);
            setPageStatus('error-fatal');

        });

    }

    //------------------------------------------------------
    //  useEffect
    //------------------------------------------------------

    // onLoad
    //  - Get the users Datasets
    useEffect(() => {

        if (getUser === undefined) return;
        if (getUser?.emailaddress === undefined) return;

        function onLoadChange(documents) {

            setDataSets(documents);
            setDataSetsFiltered(documents);

        }

        function onError(error) {

            console.log(error);

        }

        QueryListener('datasets', [
            ['users', 'array-contains', getUser?.emailaddress],
            ['status', '==', 'active']
        ], onLoadChange, onLoadChange, onError);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUser]);

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (
        <PageComponent
            status={pageStatus}
            body={
                <div className='flex flex-col px-[15px] gap-[20px]' onClick={() => search.trayOpen !== '' && setSearch({ 'trayOpen': '' })}>

                    {/* =========================================== */}
                    {/*       Home Page - Datasets & Search Box     */}
                    {/* =========================================== */}

                    {

                        (search.results.length === 0 && search.resultsOpen === false) &&

                        <>
                            {/* ========== Search Bar ========== */}
                            <div className='flex flex-row justify-center items-center gap-3'>

                                <div className='grid grid-cols-[30px_1fr] items-center gap-[15px] bg-white px-[5px] rounded-[20px] border-1 border-solid border-[#D8D8D8] w-[500px] hover:border-[#cecece] active:border-[#cecece]'>
                                    <img className='ml-[10px]' src={IconSearch} alt='searchInput-icon'></img>
                                    <label className=' flex flex-row justify-between' htmlFor='searchInput'>
                                        <input
                                            autoComplete='off'
                                            id='searchInput'
                                            className='border-none h-[40px] p-0 m-0 outline-none w-[420px]'
                                            type='text'
                                            placeholder='Search for file names across datasets'
                                            onChange={(e) => setSearch({ 'searchInput': e.target.value })}
                                            onKeyDown={(e) => handleSearch(e.keyCode, search.timeSelected, undefined, search.datasetSelected)}
                                            value={search.searchInput}
                                        ></input>
                                        <img className='mx-[10px] cursor-pointer' hidden={search.searchInput.length === 0 ? true : false} onClick={() => setSearch({ 'searchInput': '' })} src={IconClear} alt='search-Clear'></img>
                                    </label>
                                </div>

                            </div>


                            {/* ========== Browse ========== */}
                            <div className='mt-[20px]'>
                                <DatasetTable
                                    dataSets={dataSets}
                                    dataSetsFiltered={dataSetsFiltered}
                                    setDataSetsFiltered={setDataSetsFiltered}
                                ></DatasetTable>
                            </div>

                        </>

                    }

                    {/* =========================================== */}
                    {/*         Search, Tools & Results             */}
                    {/* =========================================== */}

                    {

                        (search.results.length > 0 || search.resultsOpen === true) &&

                        <div className='flex flex-col'>

                            {/* ========== Breadcrumb ========== */}
                            <p className='flex flex-row gap-1 text-[15px]'>

                                <div className='font-medium text-[#028E8C] no-underline cursor-pointer' onClick={() => {
                                    setSearch({
                                        'results': [],
                                        'searchInput': '',
                                        'resultsOpen': false,
                                    });

                                }}>
                                    Datasets
                                </div> 
                                <img src={Arrow} alt='>'></img>
                                Search
                            
                            </p>

                            {/* Search, Tools & Results */}
                            <div className='flex flex-row items-center gap-3 mb-3'>

                                {/* ========== Search Bar ========== */}
                                <div className='grid grid-cols-[30px_1fr] items-center gap-[15px] bg-white px-[5px] rounded-[20px] border-1 border-solid border-[#D8D8D8] w-[500px] hover:border-[#cecece] active:border-[#cecece]'>
                                    <img className='ml-[10px]' src={IconSearch} alt='searchInput-icon'></img>
                                    <label className=' flex flex-row justify-between' htmlFor='searchInput'>
                                        <input
                                            autoComplete='off'
                                            id='searchInput'
                                            className='border-none h-[40px] p-0 m-0 outline-none w-[420px]'
                                            type='text'
                                            placeholder='search'
                                            onChange={(e) => setSearch({ 'searchInput': e.target.value })}
                                            onKeyDown={(e) => handleSearch(e.keyCode, search.timeSelected, undefined, search.datasetSelected)}
                                            value={search.searchInput}
                                        ></input>
                                        <img className='mx-[10px] cursor-pointer' hidden={search.searchInput.length === 0 ? true : false} onClick={() => setSearch({ 'searchInput': '' })} src={IconClear} alt='search-Clear'></img>
                                    </label>

                                </div>

                                {/* Tools Tray Button */}
                                <label className={`px-2 py-1 cursor-pointer border border-solid border-[#e2e2e2] rounded-lg ${search.toolsOpen && 'bg-[#535353] text-white'}`} onClick={() => {

                                    setSearch({
                                        'toolsOpen': !search.toolsOpen,
                                        'trayOpen': '',
                                        'datasetSelected': 'Any dataset',
                                        'timeSelected': 'Any time',
                                    });
                                    handleSearch(13, 'Any time', undefined, 'Any dataset');

                                }}>Tools</label>

                            </div>

                            {/* =========================================== */}
                            {/*                 Tools Tray                  */}
                            {/* =========================================== */}
                            <SearchTools
                                search={search}
                                setSearch={setSearch}
                                dataSets={dataSets}
                                handleSearch={handleSearch}
                            ></SearchTools>

                            {/* ========== Search Results ========== */}
                            <SearchResults
                                search={search}
                            ></SearchResults>

                        </div>

                    }

                </div>
            }
        ></PageComponent>
    )
}
