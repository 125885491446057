//================================================================
//  Component: Table Filter
//================================================================

//  Purpose: Selectable drop down list on a table

//  Properties:
//    - column = {String, name of the column 'PoA Number', 'Applications', etc}
//    - currentSort = useState, with the below structure to handle sorting 
//      {
//          'column': 'PoA Number',
//          'sort': 'descending',
//      }
//    - setCurrentSort = {useState, with the above structure to handle sorting}
//    - filteredTableArray = {useState, Parent useState that will filter the table}
//    - setFilteredTableArray = {useState, Parent useState that will filter the table}
//    - objectKey = {String, name of the key you are filtering on, 'vendorname', 'applicationname', etc}
//    - styleInput = <OPTIONAL> {object, CSS style object}
//    - sticky = <OPTIONAL> {boolen, only apply this option to the first column of a table}

//    IMPORTANT!! if you are using sticky, add the below tailwind styling to the first <td> in the table.
//    'drop-shadow-md sticky left-0 bg-white'

//    <TableSorting
//      column='PoA Number'
//      currentSort={currentSort}
//      setCurrentSort={setCurrentSort}
//      filteredTableArray={agreementsFiltered}
//      setFilteredTableArray={setAgreementsFiltered}
//      objectKey='agreementid'
//      styleInput={{padding: '5px'}}
//      sticky={true}
//    ></TableSorting>

//================================================================


//Libraries
import React from 'react';

//Images
import SortOnload from '../Images/Icon_Sort_Onload.svg';
import Sort from '../Images/Icon_Sort_Teal.svg';


export default function TableSorting({
    column,
    currentSort,
    setCurrentSort,
    filteredTableArray,
    setFilteredTableArray,
    objectKey,
    styleInput,
    sticky,
}) {

    //------------------------------------------------------
    //  useContexts
    //------------------------------------------------------


    //------------------------------------------------------
    //  useStates
    //------------------------------------------------------

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    function handleSort() {

        // Flip the sort direction, this updates the UI
        let sortDirection = 'descending';
        if (currentSort.sort === 'descending') sortDirection = 'ascending'

        // Holds the results, that are saved back to the useReducer
        let results;

        // ========== ascending ========== //
        if (sortDirection === 'ascending') {


            results = filteredTableArray.sort((a, b) => {

                if (typeof (a[objectKey]) === 'string') {

                    if (a[objectKey].toLocaleLowerCase() > b[objectKey].toLocaleLowerCase()) return -1
                    if (a[objectKey].toLocaleLowerCase() > b[objectKey].toLocaleLowerCase()) return 1;
                    return 0;
                }

                if (a[objectKey] + b[objectKey]) return -1;
                if (a[objectKey] + b[objectKey]) return 1;

                return 0;

            });


        }
        
        // ========== descending ========== //
        if (sortDirection === 'descending') {

            results = filteredTableArray.sort((a, b) => {

                if (typeof (a[objectKey]) === 'string') {

                    if (a[objectKey].toLocaleLowerCase() < b[objectKey].toLocaleLowerCase()) return -1
                    if (a[objectKey].toLocaleLowerCase() < b[objectKey].toLocaleLowerCase()) return 1;
                    return 0;
                }

                if (a[objectKey] - b[objectKey]) return -1;
                if (a[objectKey] - b[objectKey]) return 1;

                return 0;
            });

        }

        setFilteredTableArray([...results]);

        setCurrentSort({
            'column': column,
            'sort': sortDirection,
        });

    }

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (
        <th className={`py-[15px] px-[20px] font-semibold whitespace-nowrap ${sticky && 'sticky left-0 bg-white drop-shadow-sm'}`}>

            <div style={styleInput} className='flex flex-row gap-2'>
                {column}
                <img
                    className={currentSort.column === column && currentSort.sort === 'descending' ? 'cursor-pointer' : 'rotate-180 cursor-pointer'}
                    onClick={() => {

                        setCurrentSort(column);

                        handleSort();

                    }}
                    src={currentSort.column === column ? Sort : SortOnload}
                    alt='Sort-Icon'
                    width='12px'
                    height='12px'
                ></img>

            </div>
        </th>
    )
}
