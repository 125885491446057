//================================================================
//  Component: DatasetTable
//================================================================

//  Purpose: Table that contains the users datasets


//  Properties:
//    - dataSets = {useState, holds the dataset documents}
//    - dataSetsFiltered = {useState, holds the filtered dataset documents}
//    - setDataSetsFiltered = {useState, holds the filtered dataset documents}


//  Example:
//      <DatasetTable
//          dataSets={dataSets}
//          dataSetsFiltered={dataSetsFiltered}
//          setDataSetsFiltered={setDataSetsFiltered}
//      ></DatasetTable>

//================================================================


//Libraries
import React, { useState } from 'react';
import { useNavigate  } from 'react-router-dom';

//Components

//Images
import TableSorting from '../../../Components/TableFiltersSorting/TableSorting';
import IconSearch from '../../../Components/Images/Icon_Search_Grey.svg';


export default function DatasetTable({
    dataSets,
    dataSetsFiltered,
    setDataSetsFiltered,
}) {

  //------------------------------------------------------
  //  React Router
  //------------------------------------------------------

    const navigate = useNavigate()

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    // Column sorting states
    const [searchInput, setSearchInput] = useState('');

    // Column sorting states
    const [currentSort, setCurrentSort] = useState({
        'column': 'Repository',
        'sort': 'descending',
    });

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    function handleDatasetSearch(value){

        setSearchInput(value);

        if (value.length === 0) {

            const options = [];
            dataSets.forEach((object) => {

                options.push(object.application);

            });

            return setDataSetsFiltered(dataSets);

        }

        const results = dataSets.filter((object) =>
            object?.datasetid?.toLowerCase().includes(value.toLowerCase()) ||
            object?.dataowner?.toLowerCase().includes(value.toLowerCase())
        );

        const options = [];
        results.forEach((object) => {

            options.push(object.application);

        });

        setDataSetsFiltered(results);

    }

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return(

        <div className='Table-Container min-w-[1280px]'>

            <h5 className='font-normal'>My Datasets</h5>

            {/* ======== Sorting and Filters ========== */}
            <div className='flex flex-row justify-between py-[10px] px-[20px] rounded-tl-[10px] rounded-tr-[10px] border-b-[#D8D8D8] border-solid border-b bg-[#F5F5F5]'>

                <label className='font-semibold self-center'>
                Total: {dataSetsFiltered.length} of {dataSets.length}
                </label>

                {/* ======== Search Bar ======== */}
                <div className='grid grid-cols-[30px_1fr] items-center gap-[15px] bg-white px-[5px] rounded-[5px] border-1 border-solid border-[#dee2e6] w-[300px]'>
                    <img className='ml-[10px]' src={IconSearch} alt='searchInput-icon'></img>
                    <label htmlFor='searchInput'>
                    <input
                        id='searchInput'
                        className='border-none h-[37px] p-0 m-0 outline-none bg-white'
                        type='text'
                        placeholder='Search'
                        onChange={(e) => handleDatasetSearch(e.target.value)}
                        value={searchInput}
                    ></input>
                    </label>
                </div>

            </div>

            <table className='w-full max-h-96 overflow-y-scroll'>
                <thead>
                <tr className='border-b-[#D8D8D8] border-solid border-b-2'>

                    <TableSorting
                        column='Repository'
                        currentSort={currentSort}
                        setCurrentSort={setCurrentSort}
                        filteredTableArray={dataSetsFiltered}
                        setFilteredTableArray={setDataSetsFiltered}
                        objectKey='projectid'
                    ></TableSorting>
                    <TableSorting
                        column='Application'
                        currentSort={currentSort}
                        setCurrentSort={setCurrentSort}
                        filteredTableArray={dataSetsFiltered}
                        setFilteredTableArray={setDataSetsFiltered}
                        objectKey='application'
                    ></TableSorting>
                    <TableSorting
                        column='Dataset'
                        currentSort={currentSort}
                        setCurrentSort={setCurrentSort}
                        filteredTableArray={dataSetsFiltered}
                        setFilteredTableArray={setDataSetsFiltered}
                        objectKey='datasetid'
                    ></TableSorting>
                    <TableSorting
                        column='Data Classification'
                        currentSort={currentSort}
                        setCurrentSort={setCurrentSort}
                        filteredTableArray={dataSetsFiltered}
                        setFilteredTableArray={setDataSetsFiltered}
                        objectKey='dataclassification'
                    ></TableSorting>
                    <TableSorting
                        column='Hosting Region'
                        currentSort={currentSort}
                        setCurrentSort={setCurrentSort}
                        filteredTableArray={dataSetsFiltered}
                        setFilteredTableArray={setDataSetsFiltered}
                        objectKey='region'
                    ></TableSorting>
                    <TableSorting
                        column='Retention Policy'
                        currentSort={currentSort}
                        setCurrentSort={setCurrentSort}
                        filteredTableArray={dataSetsFiltered}
                        setFilteredTableArray={setDataSetsFiltered}
                        objectKey='retentionpolicy'
                    ></TableSorting>
                    <TableSorting
                        column='Data Owner'
                        currentSort={currentSort}
                        setCurrentSort={setCurrentSort}
                        filteredTableArray={dataSetsFiltered}
                        setFilteredTableArray={setDataSetsFiltered}
                        objectKey='dataowner'
                    ></TableSorting>


                </tr>
                </thead>
                <tbody>
                {
                    // No dataSets found
                    dataSetsFiltered.length === 0 ? 
                    (
                    <tr>
                        <td colSpan={7}>
                        <div className='text-center p-[2%]'>
                            No dataSets found.
                        </div>
                        </td>
                    </tr>
                    )
                    : 
                    (
                    // Map in each commercial agreement
                    dataSetsFiltered.map((row, index) => (
                        <tr key={index} className='border-b-[#C8C8C9] border-solid border-b last:border-0'>
                            <td className='py-[15px] px-[20px] font-medium'>{row?.projectid}</td>
                            <td className='py-[15px] px-[20px] font-medium'>{row?.application}</td>
                            <td className='py-[15px] px-[20px] font-medium text-[#212529]'>
                                <span className='underline cursor-pointer' onClick={() => navigate(`/browse/${row.datasetid}?path=/`)}>{row?.datasetid}</span>
                            </td>
                            <td className='py-[15px] px-[20px] font-medium'>{row?.dataclassification}</td>
                            <td className='py-[15px] px-[20px] font-medium'>{row?.region}</td>
                            <td className='py-[15px] px-[20px] font-medium'>{row?.retentionpolicy}</td>
                            <td className='py-[15px] px-[20px] font-medium'>{row?.dataowner}</td>
                        </tr>
                    ))
                    )
                }
                </tbody>
            </table>

        </div>

    )

}
