//================================================================
//  Component: SearchTools of the Search Page
//================================================================

//  Purpose: Search Tool Tray for the Search Page


//  Properties:
//    - search = {useReducer, holds the search result docs and state}
//    - setSearch = {useReducer, holds the setSearch result docs and state}
//    - dataSets = {useState, holds the users datasets}
//    - handleSearch = {function, triggers the search function}

//  Example:
//      <SearchTools
//          search={search}
//          setSearch={setSearch}
//          dataSets={dataSets}
//          handleSearch={handleSearch}
//      ></SearchTools>

//================================================================


import React from 'react';

//Contexts

//Components

//Functions

//Images
import IconArrow from '../../../Components/Images/Icon_Sort.svg';
import IconDot from '../../../Components/Images/Icon_Dot_White.svg';
import IconClose from '../../../Components/Images/Close_Icon_Grey.svg';


export default function SearchTools({
    search,
    setSearch,
    dataSets,
    handleSearch,
}) {


    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (


        <div hidden={!search.toolsOpen} className='flex flex-row gap-[20px]'>

            {/* ================================= */}
            {/*        Dataset (Projects)          */}
            {/* ================================= */}

            <div className='flex flex-row cursor-pointer relative' onClick={() => {

                if (search.trayOpen === 'dataset') return setSearch({ 'trayOpen': '' });
                setSearch({ 'trayOpen': 'dataset' });

            }}>
                {search.datasetSelected}
                <img className='w-[20px]' src={IconArrow} alt='down-arrow'></img>

                {/* ============= Dropdown ============= */}
                {
                    search.trayOpen === 'dataset' &&

                    <div className='absolute flex flex-col bg-[#535353] z-50 font-light text-white w-fit top-[28px] rounded-lg shadow-xl'>

                        {/* Map in datasets */}
                        {
                            dataSets.map((object, index) => (

                                <div
                                    key={index}
                                    className='grid grid-cols-[5px,1fr] gap-2 cursor-pointer hover:bg-[#a1a1a181] p-2 first:rounded-t-lg'
                                    onClick={() => {

                                        setSearch({ 'datasetSelected': object.datasetid, 'trayOpen': '' });
                                        handleSearch(13, search.timeSelected, undefined, object.datasetid);

                                    }}
                                >
                                    <img className={`w-[6px] self-center ${search.datasetSelected === object.datasetid ? 'opacity-100' : 'opacity-0'}`} src={IconDot} alt='Icon-Dot'></img>
                                    {object.datasetid}
                                </div>

                            ))
                        }

                        {/* Default 'Any dataset' option */}
                        <label
                            className='grid grid-cols-[5px,1fr] gap-2 cursor-pointer min-w-[120px] w-full hover:bg-[#a1a1a181] p-2 last:rounded-b-lg'
                            onClick={() => setSearch({ 'datasetSelected': 'Any dataset', 'trayOpen': '' })}
                        >
                            <img className={`w-[6px] self-center ${search.datasetSelected === 'Any dataset' ? 'opacity-100' : 'opacity-0'}`} src={IconDot} alt='Icon-Dot'></img>
                            Any dataset
                        </label>

                    </div>
                }

            </div>

            {/* ================================= */}
            {/*        Time (Date Range)          */}
            {/* ================================= */}

            <div className='flex flex-row cursor-pointer relative' onClick={() => {

                if (search.trayOpen === 'time') return setSearch({ 'trayOpen': '' });
                setSearch({ 'trayOpen': 'time' });

            }}>
                {search.timeSelected}
                <img className='w-[20px]' src={IconArrow} alt='down-arrow'></img>

                {/* ============= Dropdown ============= */}
                {
                    search.trayOpen === 'time' &&

                    <div className='absolute flex flex-col bg-[#535353] z-50 font-light text-white w-[150px] top-[28px] rounded-lg shadow-xl'>

                        {
                            search.timeRanges.map((time, index) => (

                                <label
                                    key={index}
                                    className='grid grid-cols-[5px,1fr] gap-2 cursor-pointer hover:bg-[#a1a1a181] p-2 first:rounded-t-lg last:rounded-b-lg'
                                    onClick={() => {

                                        if (time === 'Custom range...') return setSearch({ 'timeSelected': time, 'trayOpen': '', 'openTimeCustomRange': true });;

                                        setSearch({ 'timeSelected': time, 'trayOpen': '' });
                                        handleSearch(13, time, undefined, search.datasetSelected);

                                    }}

                                >
                                    <img className={`w-[6px] self-center ${search.timeSelected === time ? 'opacity-100' : 'opacity-0'}`} src={IconDot} alt='Icon-Dot'></img>
                                    {time}
                                </label>

                            ))
                        }

                    </div>
                }

            </div>

            {/* ======== Custom Time Range (Modal) ======== */}
            <div hidden={!search.openTimeCustomRange} className='Modal-Background'>
                <div className='Modal-Container w-[500px] h-[230px] pl-[20px]'>

                    <div className='flex flex-row justify-between items-center font-semibold'>
                        Customised Date Range
                        <img className=' cursor-pointer' src={IconClose} alt='close-icon' onClick={() => setSearch({ 'timeSelected': 'Any time', 'openTimeCustomRange': false })}></img>
                    </div>

                    <div className='flex flex-col gap-2'>

                        <div className='grid grid-cols-[50px,1fr] items-center'>
                            From
                            <input
                                className='Input-Field-Text'
                                style={{ width: '200px' }}
                                onChange={(e) => setSearch({ 'timeStart': e.target.value })}
                                type='date'
                                value={search.timeStart}
                                max={search.timeEnd}
                            ></input>
                        </div>

                        <div className='grid grid-cols-[50px,1fr] items-center'>
                            To
                            <input
                                className='Input-Field-Text'
                                style={{ width: '200px' }}
                                onChange={(e) => setSearch({ 'timeEnd': e.target.value })}
                                type='date'
                                value={search.timeEnd}
                                min={search.timeStart}
                            ></input>
                        </div>
                    </div>

                    <div className='flex pr-[10px] justify-end'>
                        <button
                            className='Primary-Button' disabled={search.timeStart.length === 0 && search.timeEnd.length ? true : false}
                            onClick={() => {

                                setSearch({ 'openTimeCustomRange': false });
                                handleSearch(13, search.timeStart, search.timeEnd, search.datasetSelected)
                            }
                            }
                        >Go</button>
                    </div>


                </div>
            </div>

        </div>


    )

}
