//================================================================
//  Component: FileImage
//================================================================

//  Purpose: Provide an icon for search results


//  Properties:
//    - contenttype = {String, 'image', 'excel', 'pdf' and catch all is a 'generic' image}

//  Example:
//    <FileImage
//      contenttype={'image'}
//    ></FileImage>    

//================================================================


//Libraries
import React from 'react';

//Images
import FilePDF from '../Images/Icon_File_PDF.svg';
import FilePhoto from '../Images/Icon_File_Image.svg';
import FileExcel from '../Images/Icon_File_Excel.svg';
import Folder from '../Images/Icon_Folder.svg';
import FileGeneric from '../Images/Icon_File_Generic.svg';


export default function FileImage({
    contenttype,
}) {

    //------------------------------------------------------
    //  useContexts
    //------------------------------------------------------



    //------------------------------------------------------
    //  useStates
    //------------------------------------------------------



    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------



    //------------------------------------------------------
    //  useEffects
    //------------------------------------------------------



    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    if (contenttype === 'folder') {

        return (
            <img className='w-[28px] opacity-80 p-[1px] rounded' src={Folder} alt='folder-icon'></img>
        );

    }

    if (contenttype === 'application/pdf') {

        return (
            <img className='w-[28px] opacity-80 p-[1px] rounded' src={FilePDF} alt='file-pdf-icon'></img>
        );

    }

    else if (
        contenttype === 'image/svg+xml' ||
        contenttype === 'image/jpeg' ||
        contenttype === 'image/png'
    ) {

        return (
            <img className='w-[28px] opacity-80 p-[1px] rounded' src={FilePhoto} alt='file-pic-icon'></img>
        );

    }

    else if (
        contenttype === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        contenttype === 'text/csv'
    ) {

        return (
            <img className='w-[28px] opacity-80 p-[1px] rounded' src={FileExcel} alt='file-excel-icon'></img>
        );

    }

    return (
        <img className='w-[28px] opacity-80 p-[1px] rounded' src={FileGeneric} alt='file-icon'></img>
    );

}
